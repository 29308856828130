<template>
  <div>
    <button v-if="theme.value === 'dark' || (!theme.value && preferredColor.value === 'dark')" @click="theme.value = 'light'" type="button" class="h-full hover:text-accent px-4">
      <span class="sr-only">Light Theme</span>
      <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 3a6.364 6.364 0 0 0 9 9 9 9 0 1 1-9-9Z"></path></svg>
    </button>
    <button v-else @click="theme.value = 'dark'" type="button" class="h-full hover:text-accent px-4">
      <span class="sr-only">Dark Theme</span>
      <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="4"></circle><path d="M12 2v2"></path><path d="M12 20v2"></path><path d="m4.93 4.93 1.41 1.41"></path><path d="m17.66 17.66 1.41 1.41"></path><path d="M2 12h2"></path><path d="M20 12h2"></path><path d="m6.34 17.66-1.41 1.41"></path><path d="m19.07 4.93-1.41 1.41"></path></svg>
    </button>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStorage, usePreferredColorScheme } from '@vueuse/core'

const theme = reactive({
  value: useStorage('theme', localStorage.getItem('theme')),
})
const preferredColor = reactive({
  value: usePreferredColorScheme(),
})

const handleTheme = (theme) => {
  if (theme.value === 'dark') {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

watchEffect(() => {
  if (!theme.value) {
    handleTheme(preferredColor)
  } else {
    handleTheme(theme)
  }
})
</script>
