<template>
  <div>
    <button @click="openSearch" type="button" class="h-full hover:text-accent px-4">
      <span class="sr-only">Open Search</span>
      <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg>
    </button>
    <div v-show="isOpen" class="absolute inset-0 z-[1] flex bg-white dark:bg-slate-800">
      <form @submit.prevent="handleSubmit" class="flex-auto flex">
        <input ref="searchInput" v-model="searchQuery" type="text" class="form-input text-base !ring-0 rounded-none px-4 py-0" placeholder="Search…" autocomplete="off" required >
      </form>
      <button @click="closeSearch" type="button" class="h-full hover:text-accent border-l px-4">
        <span class="sr-only">Close Search</span>
        <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'

const isOpen = ref(false)
const searchQuery = ref('')
const searchInput = ref(null)

const openSearch = () => {
  isOpen.value = true
  nextTick(() => {
    searchInput.value?.focus()
  })
}

const closeSearch = () => {
  isOpen.value = false
  searchQuery.value = ''
}

const handleSubmit = () => {
  if (searchQuery.value.trim()) {
    window.location.href = `/search?q=${encodeURIComponent(searchQuery.value.trim())}`
  }
}

const handleKeydown = (e) => {
  if (e.key === 'Escape' && isOpen.value) {
    closeSearch()
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleKeydown)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeydown)
})
</script>
