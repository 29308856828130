<template>
  <div>
    <button @click="open = true" type="button" class="h-full hover:text-accent px-4">
      <span class="sr-only">Open Menu</span>
      <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="4" x2="20" y1="12" y2="12"/><line x1="4" x2="20" y1="6" y2="6"/><line x1="4" x2="20" y1="18" y2="18"/></svg>
    </button>

    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-10" @close="open = false">
        <TransitionChild as="template" enter="duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black/50 transition-opacity"></div>
        </TransitionChild>

        <div class="fixed inset-y-0 left-0 flex max-w-full">
          <TransitionChild as="template" enter="duration-500" enter-from="-translate-x-full" enter-to="translate-x-0" leave="duration-500" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="w-screen max-w-sm h-full bg-white dark:bg-slate-800 overflow-y-scroll">
              <div class="flex border-b">
                <button @click="open = false" type="button" class="hover:text-accent border-r p-4 focus:outline-none">
                  <span class="sr-only">Close Menu</span>
                  <svg class="size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                </button>
                <form action="/search" class="flex-auto flex">
                  <input name="q" type="text" class="form-input !ring-0 rounded-none px-4 py-0" placeholder="Search&hellip;" autocomplete="off" required>
                </form>
              </div>

              <div class="font-slab text-sm uppercase divide-y border-b">
                <a v-for="item in navItems" :key="item.uri" :href="item.uri" class="flex items-center h-14 hover:text-accent px-4">{{ item.label }}</a>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

const open = ref(false)
const navItems = ref([])

const MENU_QUERY = `
  query GetGlobalMenu {
    globalSet(handle: "menu") {
      ... on menu_GlobalSet {
        menu {
          uri
          label
        }
      }
    }
  }
`

onMounted(async () => {
  try {
    const response = await fetch('/index.php?action=graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: MENU_QUERY
      }),
    })
    const data = await response.json()
    navItems.value = data.data.globalSet.menu
  } catch (error) {
    console.error('Error fetching menu items:', error)
  }
})
</script>
