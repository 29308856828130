import { createApp } from 'vue';
import MobileMenu from './components/MobileMenu.vue';
import ThemeSwitcher from './components/ThemeSwitcher.vue';
import Search from './components/Search.vue';
import priorityPlus from 'priority-plus';
import 'priority-plus/dist/priority-plus.css';

const components = {
  'data-vue-mobile-menu': MobileMenu,
  'data-vue-theme-switcher': ThemeSwitcher,
  'data-vue-search': Search
};

Object.entries(components).forEach(([selector, component]) => {
  const element = document.querySelector(`[${selector}]`);
  if (element) {
    createApp(component).mount(element);
  }
});

const priorityNav = document.querySelector('.js-priority-nav');
if (priorityNav) {
  priorityPlus(priorityNav, {
    innerToggleTemplate: () => `
      <svg class="flex-none w-auto h-[18px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 4 18"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM2 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM2 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"/></svg>
      <span>More</span>
    `,
    classNames: {
      'container': ['p-plus-container', 'z-[1]', 'flex-auto', 'flex'],
      'toggle-btn': ['p-plus__toggle-btn inline-flex items-center hover:text-accent uppercase border-r px-4 space-x-3'],
      'overflow-nav': ['p-plus__overflow min-w-40 bg-white dark:bg-slate-800 ring-1 divide-y shadow-xl mt-px mr-px']
    }
  });
}

const priorityNavSub = document.querySelector('.js-priority-nav-sub');
if (priorityNavSub) {
  priorityPlus(priorityNavSub, {
    innerToggleTemplate: () => `
      <svg class="flex-none w-auto h-[18px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 4 18"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM2 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM2 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"/></svg>
      <span>More</span>
    `,
    classNames: {
      'toggle-btn': ['p-plus__toggle-btn inline-flex items-center hover:text-accent uppercase border-l px-4 space-x-3'],
      'overflow-nav': ['p-plus__overflow min-w-40 bg-white dark:bg-slate-800 ring-1 divide-y shadow-xl mt-px']
    }
  });
}

if (import.meta.hot) {
  import.meta.hot.accept();
}
